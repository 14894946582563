<template>
  <label :for="id" class="checkbox-card">
    <input :id="id" class="checkbox-card-box" type="checkbox"/>
    <div class="checkbox-card-img-container">
      <img :alt="alt" class="checkbox-card-img" :src="img">
    </div>
    <div class="checkbox-card-details">
      <h4 class="checkbox-card-title">{{ title }}</h4>
    </div>
  </label>
</template>
<script>
export default {
  name: "CheckboxCard", data() {
    return {
      id: null,
    }
  },
  props: {
    title: {
      type: String,
      default: "Title"
    },
    alt:{
      type:String,
      default:"Placeholder image",
    },
    img:{
      type:String,
      default:require("@/assets/img/placeholders/placeholder.png"),
    }
  },
  mounted() {
    this.id = this._uid;
  }
}
</script>

<style scoped>
.checkbox-card {
  @apply overflow-hidden rounded-lg;
  @apply border border-grey-base;
  @apply relative cursor-pointer;
}

.checkbox-card-img-container {
  @apply h-0 pb-48 overflow-hidden relative;
}

.checkbox-card-img {
  @apply h-full overflow-hidden w-full object-cover;
  @apply absolute left-0 top-0;
}

.checkbox-card-box {
  @apply absolute left-4 top-4;
  @apply rounded h-5 w-5 border-grey-base z-10;
}

.checkbox-card-details{
  @apply p-4;
}
.checkbox-card-title{
  @apply font-semibold;
}
</style>