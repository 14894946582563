<template>
  <div>
     <nav class="navbar">
      <div class="navbar-wrapper">
        <ButtonIcon @click.native="goToPreviousPage" />
        <ProgressIndicator :currentPage="currentStep" :totalPages="totalPages" />
      </div>
    </nav>
    <div class="form-page" v-if="isMissingParams">
      <EmptyState
        icon-color="red"
        icon-class="bx bxs-error"
        :use-link="true"
        :btn-text="$t('empty_state.something_went_wrong.cta')"
        link-path="/destinations"
        :title="$t('empty_state.something_went_wrong.title')"
        :text="$t('empty_state.something_went_wrong.text')"
      />
    </div>
    <form action="" class="form" @submit.prevent="submit">
      <div class="form-page page-4">
        <h1 class="form-page-title" v-t="'page.trip_request.experiences.title'"/>
        <div class="cards-container">
          <CheckboxCard v-for="(category, index) in categories" :key="index" :ref="category.value"
            :title="$t(category.name)" :img="category.img" @input.native="toggleExperienceList(category.value)" />
        </div>
        <ButtonPrimary :text="$t('trip_request.button')" class="desktop-cta" />
      </div>

      <div class="bottom-nav">
        <div class="bottom-nav-wrapper">
          <ButtonPrimary :text="`${$t('trip_request.button')}`" class="block h-12 px-8 w-full" />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ButtonIcon from "@/components/buttons/ButtonIcon";
import CheckboxCard from "@/components/checkboxes/CheckboxCard";
import EmptyState from "@/components/EmptyState";

export default {
  name: "Experiences",
  components: {
    CheckboxCard,
    ButtonIcon,
    EmptyState
  },
  data() {
    return {
      isMissingParams: false,
      showEmptyState: false,
      categories: [
        {
          id: 0,
          name: "page.trip_request.experiences.card.adventure_sports",
          value: "Adventure & sports",
          img: require("@/assets/img/experiences/adventure-sports.jpg"),
        },
        {
          id: 1,
          name: "page.trip_request.experiences.card.culture_history",
          value: "Culture & history",
          img: require("@/assets/img/experiences/culture-history.jpg"),
        },
        {
          id: 2,
          name: "page.trip_request.experiences.card.food",
          value: "Food",
          img: require("@/assets/img/experiences/food.jpg"),
        },
        {
          id: 3,
          name: "page.trip_request.experiences.card.mountains_hiking",
          value: "Mountains & hiking",
          img: require("@/assets/img/experiences/mountains-hiking.jpg"),
        },
        {
          id: 4,
          name: "page.trip_request.experiences.card.nature_wildlife",
          value: "Nature & wildlife",
          img: require("@/assets/img/experiences/nature-wildlife.jpg"),
        },
        {
          id: 5,
          name: "page.trip_request.experiences.card.recreation_nightlife",
          value: "Recreation & nightlife",
          img: require("@/assets/img/experiences/recreation-nightlife.jpg"),
        },
        {
          id: 6,
          name: "page.trip_request.experiences.card.seas_beaches",
          value: "Seas & beaches",
          img: require("@/assets/img/experiences/beaches.jpg"),
        },
        {
          id: 7,
          name: "page.trip_request.experiences.card.sightseeing_excursions",
          value: "Sightseeing & Excursions",
          img: require("@/assets/img/experiences/sightseeing-excursions.jpg"),
        },
        {
          id: 8,
          name: "page.trip_request.experiences.card.spa_wellness",
          value: "Spa & wellness",
          img: require("@/assets/img/experiences/spa-wellness.jpg"),
        },
        {
          id: 9,
          name: "page.trip_request.experiences.card.workshop_courses",
          value: "Workshop & courses",
          img: require("@/assets/img/experiences/workshop-courses.jpg"),
        },
      ],
    };
  },
  created() {
    this.validateURLParams();
  },
  mounted() {
    //Set experiences on mount
    for (let experience of this.experiences) {
      this.$refs[experience][0].$el.children[0].checked = true;
    }
  },
  beforeCreate() {
    if (this.isExperiencesPage === false) {
      if (this.isTravelersDetailsPage !== true) {
        this.routerPush("/request/trip-details");
      } else if (this.isTripDetailsPage !== true) {
        this.routerPush("/request");
      } else if (this.isExperience !== true) {
        this.routerPush("/request/trip-details");
      }
    }

  },
  computed: {
    ...mapGetters('request', ['step', 'experiences']),
    ...mapGetters({
      isExperiencesPage: "request/isExperiencesPage",
      isTravelersDetailsPage: "request/isTravelersDetailsPage",
      isTripDetailsPage: "request/isTripDetailsPage",
      isExperience: "request/isExperience",
      currentStep: "request/currentStep",
      totalPages: "request/totalPages",
      experiences: "request/experiences",
      formData: "request/form",
    }),
  },
  methods: {
    ...mapActions(
      {
        setIsInterestPage: "request/setIsInterestPage",
        decreasePage: "request/decreasePage",
        increasePage: "request/increasePage",
        setExperiences: "request/setExperiences",
        setFormData: "request/setFormData"
      }),
    validateURLParams() {
      let q = this.$route.query.q;
      let country = this.$route.query.country;

      if (q === undefined || country === undefined) {
        this.isMissingParams = true;
      } else {
        this.isMissingParams = false;
      }
    },
    routerPush(path) {
        this.$router.push({
          path: path,
          query: {
            q: this.$route.query.q,
            country: this.$route.query.country,
            lang: this.$route.query.lang,
          },
        });
    },
    toggleExperienceList(experience) {
      let experiences = this.experiences;

      //Experience is already in the list, then remove it
      if (experiences.includes(experience))
        experiences = experiences.filter(exp => exp !== experience);
      else
        //Else add it to the list
        experiences.push(experience);
      this.setExperiences(experiences);
    },
    storeExperienceInLocalStorage() {
      let result = '';

      if (this.experiences.length > 0)
        for (let [index, item] of this.experiences.entries()) {
          let comma = this.experiences.length === index + 1 ? '' : ', ';
          result += item.title + comma;
        }
      return result;
    },
    goToPreviousPage() {
      this.decreasePage();
      this.routerPush("/request/traveler-details");
    },
    submit() {
      let experiences = this.experiences.toString().replaceAll(",", ", ");

      this.$store.commit(
        'request/SET_EXPERIENCE_DETAILS',
        experiences
      );
      if (this.experiences.length > 0) {
        this.setIsInterestPage(true);
        this.increasePage();
        this.setFormData({experiences:this.experiences});
        this.routerPush("/request/interest");
      } else {
        this.$notify({
          type: "error",
          text: this.$t('page.trip_request.experiences.error.at_least_one_experience'),
        })
      }
    },
  },
};
</script>

<style scoped>
.navbar {
  @apply top-0 flex flex-wrap items-center w-full h-20 px-4 bg-white md:px-12;
}

.navbar-wrapper {
  @apply relative flex items-center justify-between w-full h-12 max-w-screen-md transform -translate-x-1/2 left-1/2;
}

.back-btn {
  @apply flex items-center p-3 text-xl duration-300 rounded focus:outline-none focus:ring-2 focus:ring-black-lighter;
  @apply focus:scale-95 focus:ring-offset-2 hover:text-black-base transition ease-in-out text-black-lightest;
}

.form {
  @apply w-full px-4 pb-48 mt-10 md:px-12;
}

.form-page {
  @apply relative max-w-screen-sm transform -translate-x-1/2 left-1/2;
}

.form-page-title {
  @apply mb-12 font-sans text-xl font-bold sm:text-2xl text-black-base;
}

.cards-container {
  @apply grid gap-8 mt-6 xs:grid-cols-2;
}


.bottom-nav {
  @apply fixed bottom-0 left-0 w-full p-4 bg-white border-t border-grey-base sm:mt-16;
  @apply block sm:hidden;
}

.bottom-nav-wrapper {
  @apply relative flex items-center justify-between max-w-screen-sm transform -translate-x-1/2 left-1/2;
}

.desktop-cta {
  @apply hidden sm:block h-12 px-8 w-full mt-8;
}
</style>
